import { useContext, useEffect, useState } from "react"
import { useRouter } from "next/router"
import ReactHtmlParser from "react-html-parser"
import { getCopy, getHeadline } from "../../utils/helpers"
import { ServiceContext } from "../../pages/[id]/[slug]"
import Recurring from "./Recurring"
import NoCampaignOffer from "./NoCampaignOffer"
import ValidCampaignOffer from "./ValidCampaignOffer"
import ValuePropCampaignOffer from "./ValuePropCampaignOffer"
import { PaymentSelector } from "./PaymentSelector"
import { defaultOneTimeGivingAmount, defaultMonthlyGivingAmount, minimumMinistryPartnerDonation } from "../../utils/helpers"

export default function CampaignDetails({ campaign }) {
  const router = useRouter()
  const { donationAmountService, recurringService } = useContext(ServiceContext)

  const urlAmount = (
    (router.query.oneTime || router.query.amount || "") as string
  )?.replace("$", "")

  const urlRecurringAmount = (
    (router.query.monthlyamount || "") as string
  )?.replace("$", "")

  const urlPartner = (router.query.partner?.length && router.query.partner !== "no")

  const isFree = campaign.offer?.free
  const isOneTime = !isFree && campaign.enable_one_time
  const isRecurring = !isFree && campaign.enable_recurring

  const [isOneTimeVisible, setIsOneTimeVisible] = useState(false)
  const [isRecurringVisible, setIsRecurringVisible] = useState(false)
  
  useEffect(() => {
    // If the url or the campaign indicates we should start with a recurring
    // donation, change it now.
    
    let defaultRecurring
    if (isRecurring && !isOneTime) {
      // If the campaign allows recurring but not one time 
      // donations, default to recurring
      defaultRecurring = true
    }
    else if (Boolean(urlAmount) === Boolean(urlRecurringAmount)) {
      // If the url has both a one-time and an recurring amount (shouldn't happen)
      // or neither (should be common), then use the default specified in the campaign.
      if (isRecurring && campaign.default_recurring) {
        defaultRecurring = true
      }
    } else {
      // Otherwise, set it to recurring if there's a recurring amount in the url
      if (isRecurring && Boolean(urlRecurringAmount)) {
        defaultRecurring = true
      }
    }

    // Set defaults based on url querystring, campaign options, and standard defaults
    // Only do this once, for the initial page load
    
    if (defaultRecurring) {
      const amount = urlRecurringAmount
      if (amount) {
        if (campaign?.recurring_amounts?.includes(amount)) {
          recurringService.send({
            type: "CHANGE",
            key: "amountSelected",
            value: amount
          })
        } else {
          recurringService.send({
            type: "CHANGE",
            key: "amount",
            value: amount
          })
        }
      }
      
      if (urlPartner && Number(amount) >= minimumMinistryPartnerDonation) {
        recurringService.send({
          type: "CHANGE",
          key: "ministryPartnerChecked",
          value: true
        })
      }
      
      recurringService.send({
        type: "CHANGE",
        key: "recurring",
        value: true
      })
    } else {
      const amount = urlAmount
      if (amount) {
        if (campaign.amounts.includes(amount)) {
          donationAmountService.send({
            type: "CHANGE",
            key: "amountSelected",
            value: amount
          })
        } else {
          donationAmountService.send({
            type: "CHANGE",
            key: "amount",
            value: amount
          })
        
          donationAmountService.send({
            type: "CHANGE",
            key: "recurring",
            value: false
          })
        }
      }
    }
  }, [])
  
  useEffect(() => {
    const subscription = recurringService.subscribe((state) => {
      setIsOneTimeVisible(isOneTime && !state.context.values.recurring)
      setIsRecurringVisible(isRecurring && state.context.values.recurring)
    })

    return subscription.unsubscribe
  }, [recurringService])
 
  const clickOneTime = () => {
    if (!isOneTimeVisible) {
      donationAmountService.send("RESET")
      recurringService.send("RESET")
      recurringService.send({
        type: "CHANGE",
        key: "recurring",
        value: false
      })
    }
  }

  const clickRecurring = () => {
    if (!isRecurringVisible) {
      donationAmountService.send("RESET")
      recurringService.send("RESET")
      recurringService.send({
        type: "CHANGE",
        key: "recurring",
        value: true
      })
    }
  }

  return (
    <div className="primary-col">
      <h1 className="t1 center mb-4">{getHeadline(campaign)}</h1>
      <>
        {isOneTime && isRecurring ? (
          <div style={{display: "flex", justifyContent: "center", marginBottom: "8px"}}>
            <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", columnGap: "14px", paddingLeft: "12px"}}>
              <button
                type="button"
                className={isOneTimeVisible ? "active" : ""}
                id="recurring-btn"
                onClick={clickOneTime}
              >
                One Time
              </button>
              <span>or</span>
              <button
                type="button"
                className={isRecurringVisible ? "active" : ""}
                id="recurring-btn"
                onClick={clickRecurring}
              >
                Monthly
              </button>
            </div>
          </div>
        ) : null}
        {isOneTimeVisible ? (
          <PaymentSelector donationService={donationAmountService} campaign={campaign} amounts={campaign.amounts} />
        ) : null}
        {isRecurringVisible ? <Recurring campaign={campaign} /> : null}
      </>
      <p className="mt-4 center ss2">{ReactHtmlParser(getCopy(campaign))}</p>
      {campaign.video ? (
        <>
          <div className="responsive-video mt-4">
            <iframe
              title="campaign video"
              src={campaign.video}
              width="640"
              height="360"
              frameBorder="0"
              allowFullScreen={true}
            ></iframe>
          </div>
        </>
      ) : null}
      {!campaign.offer && <NoCampaignOffer campaign={campaign} />}
      {campaign.offer && campaign.offer?.allowed_products && (
        <ValidCampaignOffer campaign={campaign} />
      )}
      {campaign.offer?.products.length && (
        <ValuePropCampaignOffer campaign={campaign} />
      )}{" "}
    </div>
  )
}
