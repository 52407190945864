import { useQuery } from "@tanstack/react-query"
import axios from "axios"
import { useLDClient } from "launchdarkly-react-client-sdk"
import { useSession } from "next-auth/react"
import { useEffect } from "react"
import { UserData } from "../pages/api/user"

export const useUserData = () => {
  const { data: session } = useSession()
  const ldClient = useLDClient()

  const {
    data: user,
    isLoading,
    error
  } = useQuery<UserData>({
    queryKey: ["user"],
    queryFn: async () => {
      const response = await axios.get("/api/user")
      return response.data
    },
    enabled: !!session
  })

  useEffect(() => {
    if (user) {
      const ldUser = user.email
        ? {
            key: user.email,
            email: user.email,
            anonymous: false
          }
        : null

      console.log("ldUser", ldUser)

      ldClient.identify(ldUser)
    }
  }, [user])

  return {
    user,
    isLoading,
    error
  }
}
