import { ReactNode, useContext, useState } from "react"
import { useDonationAmount } from "../../hooks/useDonationAmount"
import { format } from "date-fns"
import { ServiceContext } from "../../pages/[id]/[slug]"

const GiftSummaryRow = ({
  label,
  value
}: {
  label: ReactNode
  value: string
}) => (
  <div className="gift-summary-row">
    <p>{label}</p>
    <p>{value}</p>
  </div>
)

export const GiftSummary = ({
  enableDonorCoveredFees
}: {
  enableDonorCoveredFees: boolean
}) => {
  const { donationAmountService } = useContext(ServiceContext)

  const {
    isDonating,
    donationTodayAmount,
    donationFeesCovered,
    feesCalculator,
    feesAndAmountCalculator,
    recurStartDate,
    recurringGiftAmount
  } = useDonationAmount()

  const [donorCoveredFeesTooltip, setDonorCoveredFeesTooltip] = useState(false)

  const startDateDisplay = recurStartDate
    ? format(recurStartDate, "MMM do")
    : ""

  const handleCoverFeesChange = () => {
    donationAmountService.send({
      type: "TOGGLE_FEES_COVERED"
    })
  }

  const displayAmount = (amount: string) => `$${amount}`

  return isDonating ? (
    <div className="gift-summary">
      {enableDonorCoveredFees && (
        <div className="covered-fees-container">
          <input
            type="checkbox"
            id="cover-fees"
            name="cover-fees"
            checked={donationFeesCovered}
            className="sr-only"
            onChange={handleCoverFeesChange}
          />
          <label htmlFor="cover-fees" className="covered-fees-label">
            Help cover processing fees?
            <button
              type="button"
              id="learn-more-btn"
              aria-label="learn more about covered processing fees"
              onClick={() => setDonorCoveredFeesTooltip((value) => !value)}
              className="covered-fees-tooltip-button"
            >
              <svg
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                width="16"
                height="16"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                ></path>
              </svg>
            </button>
          </label>
          {donorCoveredFeesTooltip && (
            <div className="covered-fees-tooltip">
              {Number(donationTodayAmount) && !Number(recurringGiftAmount) ? (
                <p>
                  This will increase your gift today by{" "}
                  <span className="underline">
                    {displayAmount(feesCalculator(donationTodayAmount))}
                  </span>{" "}
                  to help cover credit card and bank processing fees.
                </p>
              ) : null}
              {Number(recurringGiftAmount) ? (
                <p>
                  This will increase both your gift today and your monthly gift by{" "}
                  <span className="underline">
                    {displayAmount(feesCalculator(recurringGiftAmount))}
                  </span>{" "}
                  to help cover credit card and bank processing fees.
                </p>
              ): null}
            </div>
          )}
        </div>
      )}
      <p id="gift-summary-title">
        <span>SUMMARY</span>
      </p>
      <div id="gift-summary-body">
        {Number(donationTodayAmount) && (
          <div>
            <GiftSummaryRow label="Your Gift Today:" value={(feesAndAmountCalculator(donationTodayAmount))}/>
          </div>
        )}
        {Number(recurringGiftAmount) ? (
          <div>
            <GiftSummaryRow label={`Your Recurring Gift Beginning ${startDateDisplay}:`} value={feesAndAmountCalculator(recurringGiftAmount)}/>
          </div>
        ) : null}
      </div>
    </div>
  ) : null
}
