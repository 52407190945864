import { useContext } from "react"
import { XStateContext } from "../../contexts/XStateCtx"
import { NEW_PAYMENT_METHOD } from "./Payment"

export const PaymentMethods = ({ paymentMethods }) => {
  const xStateServices = useContext(XStateContext)
  const { send } = xStateServices.paymentMethodsService
  const handleChangePaymentMethod = (e) => {
    send({
      type: "SET_SELECTED_PAYMENT_METHOD",
      data: e.target.value
    })
  }

  if (!paymentMethods?.length) return null

  return (
    <div className="paymentMethods mb-3" onChange={handleChangePaymentMethod}>
      {paymentMethods?.map((paymentMethod, index) => {
        return (
          <div className="mb-1" key={paymentMethod.id}>
            <input
              id={`${paymentMethod.id}`}
              key={paymentMethod.id}
              type="radio"
              value={paymentMethod.id}
              name="savedPayments"
              // The first payment method is selected by default
              // in the useEffect above, this matches the UI with that action
              defaultChecked={index === 0}
            />
            <label
              className="paymentMethods_label"
              htmlFor={`${paymentMethod.id}`}
            >
              {paymentMethod.display}
            </label>
          </div>
        )
      })}
      <div>
        <input
          id={NEW_PAYMENT_METHOD}
          type="radio"
          value={NEW_PAYMENT_METHOD}
          name="savedPayments"
        />
        <label className="paymentMethods_label" htmlFor={NEW_PAYMENT_METHOD}>
          Enter a new debit or credit card
        </label>
      </div>
    </div>
  )
}
